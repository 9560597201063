export const en1 = {
  "Администратор": "Administrator",
  "Адрес": "Address",
  "Адрес изготовителя": "Manufacturer's address",
  "Адрес регистрации": "Registration address",
  "Адрес торговой точки": "Sales outlet address",
  "Адрес:": "Address:",
  "АЗС": "Gas station",
  "Активация категории": "Category activation",
  "Активация торговой точки": "Point of sale activation",
  "активен": "active",
  "Активен": "Active",
  "Активировать": "Activate",
  "Активировать торговую точку": "Activate trade point",
  "активна": "active",
  "Активна": "Active",
  "Активность": "Activity",
  "Активные устройства": "Active devices",
  "Активный": "Active",
  "Активный товар": "Active item",
  "Активных": "Active",
  "Активных корзин пока нет": "No active carts yet",
  "Акты": "Acts",
  "Артикул": "vendor code",
  "Архивных корзин пока нет": "No archive carts yet",
  "Базовая цена": "Base Price",
  "Базовый каталог": "Base directory",
  "Банковские реквизиты:": "Bank details:",
  "Без разогрева": "Without warming up",
  "Белки": "Protein",
  "БИК": "BIK",
  "Боковые стикеры": "Side Stickers",
  "Брискли Бизнесс": "Briskley Business",
  "Будет сгенерирован при сохранении": "Will be generated on save",
  "в валюте": "in currency",
  "В выбранном кателоге нет товаров": "There are no products in the selected catalog",
  "В данном разделе вам необходимо ввести пятизначный код для активации модуля.": "In this section, you need to enter a five-digit code to activate the module.",
  "В корзине пока нет товаров": "No items in the cart yet",
  "В корзине:": "In cart:",
  "В настройках торговой точки вы можете настроить расписание работы, включить / отключить уведомления, а также назначить сотрудников.": "In the outlet settings you can set up the work schedule, enable / disable notifications and assign employees.",
  "В приложении": "In the application",
  "в процентах": "in percents",
  "В случае деактивации торговая точка будет недоступна в приложении BPay": "If deactivated, the outlet will be unavailable in the B-Pay app",
  "В торговую точку": "To the point of sale",
  "В этом разделе вы сможете контролировать и создавать поставки на ваши склады.": "In this section you will be able to control and create deliveries to your warehouses.",
  "В этом разделе вы сможете отслеживать и создавать списания ваших товаров, назначать ответсвенных за этот процесс": "In this section you will be able to track and create write-offs of your goods, assign persons responsible for this process",
  "В этом разделе появятся акты, которые вы заключили с вашими контрагентами.": "In this section, the acts that you have concluded with your counterparties will appear.",
  "Валюта": "Currency",
  "Ваш логотип": "Your logo",
  "Введите адрес...": "Enter the address ...",
  "Введите артикул...": "Enter SKU ...",
  "Введите возрастное ограничение": "Enter your age limit",
  "Введите значение": "Enter value",
  "Введите значение...": "Enter value...",
  "Введите ключ...": "Enter the key ...",
  "Введите код...": "Enter code...",
  "Введите название": "Enter the name",
  "Введите название каталога": "Enter the name of the directory",
  "Введите название категории...": "Enter category name ...",
  "Введите название торговой точки...": "Enter the name of the outlet ...",
  "Введите название...": "Enter the name...",
  "Введите наименование": "Enter the name",
  "Введите наименование...": "Enter name ...",
  "Введите описание": "Enter a description",
  "Введите описание...": "Enter a description ...",
  "Введите причину списания": "Enter the reason for debiting",
  "Введите размер скидки": "Enter the discount amount",
  "Введите размерность...": "Enter dimension ...",
  "Введите срок годности...": "Enter expiration date ...",
  "Введите сумму": "Enter the amount",
  "Введите цену товара": "Enter product price",
  "Введите цену...": "Enter price ...",
  "Введите ID или название товара": "Enter ID or product name",
  "введите ID клиента": "enter client ID",
  "Введите...": "Enter ...",
  "вернуть весь заказ": "return the entire order",
  "вернуть товар/ы": "return product / s",
  "Вернуться": "Return",
  "Вернуться в торговую точку": "Return to point of sale",
  "Вернуться к добавлению товаров": "Back to adding products",
  "Вернуться к Каталогам": "Back to Catalogs",
  "Вернуться к Категориям": "Back to Categories",
  "Вернуться к скидкам": "Back to discounts",
  "Вернуться к списку": "Back to the list",
  "Вернуться к Товарам": "Back to Products",
  "Вернуться назад": "Back",
  "Видео": "Video",
  "Видео отстутствует": "There is no video",
  "Видео:": "Video:",
  "Виртуальные терминалы": "Virtual terminals",
  "Виртуальный терминал": "Virtual terminal",
  "Виртуальный терминал :id": "Virtual terminal: id",
  "Виртуальный терминал ID :id": "Virtual terminal ID: id",
  "Включена": "Included",
  "Включить доступ": "Enable access",
  "Внимание!\r\nВы уверены, что хотите покинуть страницу? Все несохранённые данные будут утеряны.": "Warning.\r\nAre you sure you want to leave the page? All unsaved data will be lost.",
  "Внимание! Категория должна быть привязана к какому либо каталогу.\r\n\r\nЕсли вам необходимо создать новый каталог перейдите по ссылке": "Attention! The category must be linked to a directory.\r\n\r\nIf you need to create a new directory go to",
  "Внимание! Категория может быть прикреплена только к одному каталогу. Создайте новую категорию и прикрепите её к каталогу. Вы также можете пропустить этот шаг чтобы вернуться к нему позже из экрана каталога.\r\n\r\nПрикреплённые категории": "\"Warning. A category can only be attached to one catalog. Create a new category and attach it to the catalog. You can also skip this step to return to it later from the catalog screen.\r\n\r\nAttached Categories\"\r\nNext",
  "Возврат": "Refund",
  "Возвраты": "Refunds",
  "Возвращено товаров:": "Returned goods:",
  "Возмещения": "Refunds",
  "Возмещения отсутствуют": "No refunds",
  "Возрастное ограничение": "Age limit",
  "Возрастное ограничение 18+": "Age limit 18+",
  "Возрастное ограничение:": "Age limit:",
  "Вперед": "Go to",
  "Вперёд": "Next",
  "Время": "Time",
  "Время действия": "Time of action",
  "Время действия:": "Time of action:",
  "Время оплаты:": "Payment Time:",
  "Время сборки:": "Assembly time:",
  "Время создания": "Time of creation",
  "Время создания:": "Time of creation:",
  "все": "all",
  "Все": "Everything",
  "Все дни:": "All days:",
  "Все каталоги": "All catalogs",
  "Всего товаров к возврату:": "Total items to be returned:",
  "Всего товаров:": "Total products:",
  "Всего:": "Total:",
  "Вы действительно хотите удалить устройство?": "Are you sure you want to delete the device?",
  "Вы можете добавить товары к складу своей торговой точке, прикрепив к ней каталог. Или создать новый каталог и заполнить его товарами": "You can add goods to the stock of your sales outlet by attaching a catalog to it. Or create a new catalog and fill it with goods",
  "Вы можете привязать несколько категорий к каталогу.": "You can link multiple categories to a catalog.",
  "Вы можете add products вручную или load products через .xlsx": "You can add products manually or load products via .xlsx",
  "Выберите": "Please select",
  "Выберите валюту": "Select currency",
  "Выберите дату": "Choose a date",
  "Выберите единицу измерения...": "Select a unit ...",
  "Выберите каталог": "Select a directory",
  "Выберите каталог...": "Select directory ...",
  "Выберите категорию": "Select a category",
  "Выберите категорию...": "Select a category...",
  "Выберите магазин": "Select store",
  "Выберите налоги...": "Select taxes ...",
  "Выберите объем": "Select volume",
  "Выберите отчётный период": "Select the reporting period",
  "Выберите поля, которые хотите видеть в таблице.": "Select the fields you want to see in the table.",
  "Выберите размер наклейки...": "Select sticker size ...",
  "Выберите сотрудника": "Select an employee",
  "Выберите столбец...": "Select a column ...",
  "Выберите тип напитка": "Select the type of drink",
  "Выберите товар": "Select a product",
  "Выберите товары": "Select products",
  "Выберите товары для отображения в меню": "Select products to display in the menu",
  "Выберите торговую точку": "Select a retail outlet",
  "Выберите торговую точку...": "Select a sales outlet ...",
  "Выберите торговые точки": "Select outlets",
  "Выберите формат в котором будет экспортирован список отчетов": "Select the format in which the list of reports will be exported",
  "Выберите формат для экспорта": "Select a format for export",
  "Выберите...": "Select ...",
  "Выбор каталога": "Directory selection",
  "Выбор фотографии": "Photo selection",
  "Выбор языка": "Language Selection",
  "Выбран": "Selected",
  "Выбранное устройство": "Selected device",
  "Выбрано": "Selected",
  "Выбрано товаров": "Selected items",
  "Выбрано:": "Selected:",
  "Выбрать": "Select",
  "выбрать дату": "select date",
  "Выбрать категорию": "Select a category",
  "выбрать период": "choose period",
  "Выбрать сотрудника": "Select an employee",
  "Выбрать существующий": "Select existing",
  "Выбрать товар": "Select a product",
  "Выручка": "Revenue",
  "Выходной": "Day off",
  "г": "G",
  "Где уведомлять": "Where to notify",
  "Генеральный директор:": "General manager:",
  "Главная": "Home",
  "год": "year",
  "Горизонтальная инструкция": "Horizontal instruction",
  "Гражданство": "Citizenship",
  "Группа товаров, которые воспринимаются покупателями как взаимосвязанные и взаимозаменяемые. Например, супы или напитки.": "A group of goods that are perceived by customers as interrelated and interchangeable. For example soups or drinks.",
  "Группировать по": "Group by",
  "Группировать по:": "Group by:",
  "Группировка": "Grouping",
  "д": "d",
  "да": "Yes",
  "Далее": "Next",
  "Данные виртуального терминала заполнены успешно, и направлены на проверку.": "The virtual terminal data has been filled in successfully and sent for verification.",
  "Данные организации": "Organization data",
  "Данные руководителя:": "Manager data:",
  "Данный номер телефона будет использован в чеках оплаты приложения B-Pay.": "This phone number will be used in your B-Pay checks.",
  "Дата выдачи": "date of issue",
  "Дата и время": "date and time",
  "Дата и время заказа": "Date and time of order",
  "Дата и время перемещения": "Date and time of movement",
  "Дата и время поставки": "Delivery date and time",
  "Дата и время списания": "Date and time of debiting",
  "Дата изготовления": "Manufacturing date",
  "Дата изготовления:": "Manufacturing date:",
  "Дата оплаты": "date of payment",
  "Дата рождения": "Date of Birth",
  "Дата создания": "date of creation",
  "Дата создания платежа": "Payment creation date",
  "Дата создания:": "Date of creation:",
  "Дата транзакции": "Transaction date",
  "Дата:": "Date:",
  "Дверь": "Door",
  "Дверь микромаркета": "Micromarket door",
  "Деактивация категории": "Category deactivation",
  "деактивирован": "deactivated",
  "День": "Day",
  "День \tКоличество \tСумма \tПродажи \tПоступления \tКомиссия": "Day Amount Sales Amount Receipts Commission",
  "Дизайн": "Design",
  "Для активации торговой точки и её полного функционирования необходимо заполнить 2 основных раздела.": "To activate the store and get its full functionality, it's necessary to fill in 2 main sections.",
  "Для активации торговой точки и её полного функционирования необходимо заполнить 3 основных раздела.": "To activate a sales outlet and make it fully functional, you need to fill out 3 main sections.",
  "Для дальнейшего использования реквизиты должны быть верно заполнены и проверены нашим специалистом. Вы можете fill in now недостающие данные или вернуться к этому позже.": "For further use, the details must be filled in correctly and verified by our specialist. You can fill in now the missing data or come back to it later.",
  "Для добавления реквизитов, введите ИНН нового юридического лица.": "To add details, enter the TIN of the new legal entity.",
  "Для добавления товаров в торговую точку выберите каталог или создайте новый.": "To add products to a sales outlet, select a catalog or create a new one.",
  "Для добавления товаров Вам необходимо create directories и Categories .\r\nВ случае загрузки товаров через .xlsx и наличия в файле заполненной графы “Категория”": "Категории при этом будут созданы автоматичеки.\"",
  "В случае загрузки товаров через .xlsx и наличия в файле заполненной графы “Категория”": "Категории при этом будут созданы автоматичеки.\"",
  "If you upload your products via .xlsx and if the \"\"Category\"\" field is filled in the file": "the Categories will be created automatically.\"",
  "Для одной позиции": "For one position",
  "Для привязки устройства к вашей торговой точке, необходимо его активировать. Для этого введите 5-значный код, выданный вам при покупке.": "To link the device to your point of sale, you need to activate it. To do this, enter the 5-digit code given to you at the time of purchase.",
  "Для продаж нужны товары. Создайте или прикрепите\r\nсклад с товарами к вашей торговой точке": "You need merchandise for sales. Create or link\r\nwarehouse with goods to your point of sale.",
  "Для создания категории введите её название и по необходимости определите возрастное ограничение.": "To create a category enter its name and, if necessary, define the age limit.",
  "Для формирования отчёта по движению товаров необходимо задать следующие параметры: период времени, выбрать одну или несколько торговых точек и товар(ы), движение по которым вас интересует.": "To generate a report on the movement of goods, you must set the following parameters: time period, select one or more outlets and product(s), the movement of which you are interested.",
  "Для формирования отчёта укажите необходимые параметры выше и нажмите кнопку «Сформировать отчёт».": "To generate a report, specify the required parameters above and click the \"Generate Report\" button.",
  "Дни недели": "Days of the week",
  "Дни недели в раписании": "Перевести дни недели",
  "Добавить": "Add",
  "Добавить адрес": "Add address",
  "Добавить вид товара": "Add product type",
  "Добавить виртуальный терминал": "Add virtual terminal",
  "Добавить вручную": "Add manually",
  "Добавить еще": "Add more",
  "Добавить каталог": "Add catalog",
  "Добавить категорию": "Add category",
  "Добавить магазин": "Add store",
  "Добавить меню": "Add menus",
  "Добавить перемещение": "Add move",
  "Добавить поставку": "Add delivery",
  "Добавить реквизиты": "Add requisites",
  "Добавить скидку": "Add discount",
  "Добавить сотрудника": "Add employee",
  "Добавить списание": "Add write-off",
  "Добавить срок годности в штрихкод": "Add expiration date to barcode",
  "Печатать цену": "Print price",
  "Добавить счёт": "Add bill",
  "добавить терминал": "add terminal",
  "Добавить товар": "Add product",
  "добавить товары": "add products",
  "Добавить торговую точку": "Add a tradepoint",
  "Добавить точку": "Add tradepoint",
  "Добавить устройство": "Add device",
  "Добавлен": "Added",
  "Добавлен:": "Added:",
  "Добавление скидки": "Adding a discount",
  "Добавление товаров": "Adding products",
  "Добавление торговых точек": "Adding outlets",
  "Добавьте новые реквизиты": "Add new details",
  "Добавьте первый счёт на оплату.": "Add the first bill for payment.",
  "Добавьте первый товар в новый каталог и он станет доступен в вашей торговой точке": "Add the first product to the new catalog and it will become available at your outlet",
  "Добавьте сопутствующие товары чтобы мы могли предложить их при оформлении покупки в приложении BPay.": "Add related products so we can suggest them at checkout in the B-Pay app.",
  "Документация": "Documentation",
  "Документы": "Documents",
  "Дополнительное описание товара": "Additional product description",
  "Дополнительный вид товара": "Additional type of product",
  "Дополнительный код": "Additional code",
  "Доставка": "Delivery",
  "Доставка партнерами Briskly": "Delivery by Briskly partners",
  "Доставки": "Delivery",
  "Доступ ко всему функционалу личного кабинета": "Access to all the functionality of your personal account",
  "Доступ по ссылке:": "Access via link:",
  "Доступна возможность открывать микромаркет, менять товар, делать фото, загружать фото в интерфейс курьера": "Available the ability to open a micromarket, change the product, take photos, upload photos to the courier interface",
  "Доступна возможность принимать заказы покупателей и собирать их в торговом зале для отправки клиенту.": "Available to take customer orders and collect them on the sales floor for shipment to the customer.",
  "Единица измерения": "Unit of measurement",
  "Ежедневно": "Daily",
  "Если вам необходимо создать новый каталог перейдите по ссылке": "If you need to create a new directory follow the link",
  "Если у вас фирменный стаканчик для кофе, то включите этот пункт и назначьте цену.": "If you have a branded coffee cup, turn this item on and set a price.",
  "Жиры": "Fats",
  "За все время": "In all the time",
  "заблокирован": "locked",
  "Завершен": "Completed",
  "Завершение": "Completion",
  "Завершите регистрацию": "Complete registration",
  "Завершить": "To complete",
  "Заголовок": "Heading",
  "Заголовок:": "Title:",
  "Загрузить": "Download",
  "загрузить товары": "load products",
  "Загрузка логотипа торговой точки": "Uploading a store logo",
  "Загрузка фото товара": "Uploading a product photo",
  "Заказ": "Order",
  "Заказ №": "Order no.",
  "Заказов сегодня": "Orders today",
  "Закрытая территория": "Closed area",
  "Замок микромаркета": "Micromarket castle",
  "Заполните все необходимые поля.": "Fill all necessary fields.",
  "Заполните наименование платежа и его сумму, а затем скопируйте и отправьте ссылку вашему клиенту.": "Fill in the name of the payment and its amount, then copy and send the link to your customer.",
  "Заполните штрихкод": "Fill in the barcode",
  "Заполните штрихкод...": "Fill in the barcode ...",
  "Заполнить": "Fill in",
  "Заполнить недостающие данные": "Fill in missing data",
  "заполнить сейчас": "fill in now",
  "Здесь будет информация о перемещении ваших товаров из одной торговой точки в другую. Чтобы изменить область видимости товара, создайте перемещение": "Here will be information about the movement of your goods from one sales point to another. To change the product visibility area, create a move",
  "Здесь вы сможете отслеживать статус ваших счетов на оплат.": "Here you can track the status of your payment invoices.",
  "Значение QR-кода": "QR code meaning",
  "Из торговой точки": "From a point of sale",
  "Изменения сохранены": "Changes saved",
  "Изменения успешно сохранены": "Changes saved successfully",
  "Изменить": "Change",
  "Измерение": "Measurement",
  "Измерение:": "Measurement:",
  "или": "or",
  "Имя": "Name",
  "Имя сотрудника": "Employee name",
  "Индивидуальный предприниматель": "Individual entrepreneur",
  "ИНН": "INN",
  "ИНН или наименование юридического лица": "TIN or name of a legal entity",
  "ИНН организации": "TIN of the organization",
  "ИНН, КПП или название компании": "TIN, KPP or company name",
  "Инструкции": "Instructions",
  "Инструкция": "Instructions",
  "Интеграция": "Integration",
  "Информация о покупателе": "Buyer information",
  "Информация о сотруднике": "Employee information",
  "Информация об изменении статуса новых реквизитов будет отправлена в push-уведомлении, а также отобразится в карточке на странице «Юридические лица».": "Information about changes in the status of the new details will be sent in a push-notification, and will also be displayed in the card on the page \"Legal entities\".",
  "Информация по обновлениям": "Update information",
  "Искать везде": "Search everywhere",
  "Исключённая стоимость": "Excluded cost",
  "Используется, если для товара необходимо сканирование дополнительного кода (Data Matrix, ЕГАИС)": "It is used if scanning of an additional code is required for the product (Data Matrix, EGAIS)",
  "История обновлений": "Update history",
  "Источник": "A source",
  "Итог. сумма": "Bottom line. sum",
  "Итого": "Total",
  "Итоговая сумма": "Total amount",
  "Итоговая цена": "Total price",
  "Итоговый остаток": "Total balance",
  "К списку": "To the list",
  "Календарь": "Месяцы перевести",
  "Калории": "Calories",
  "Каталог": "Catalog",
  "Каталог №": "Catalog no.",
  "Каталог:": "Catalog:",
  "Каталоги": "Catalogs",
  "Каталоги и категории": "Catalogs and categories",
  "Категории": "Categories",
  "Категорий:": "Categories:",
  "Категория": "Category",
  "Категория товаров и услуг": "Category of goods and services",
  "Категория №": "Category No.",
  "Категория:": "Category:",
  "Кем выдан": "Issued by",
  "Клиент": "Customer",
  "Клиент сам заберет заказ по указанному адресу": "The customer will pick up the order at the address specified",
  "Ключ привязки": "Binding key",
  "Код активации": "Activation code",
  "Код подразделения": "Department code",
  "Код привязки модуля": "Module binding code",
  "Код привязки": "Binding code",
  "Кол-во": "Qty",
  "Кол-во товаров": "Quantity of goods",
  "Количество наклеек": "Number of stickers",
  "Количество товара": "Quantity of goods",
  "Количество товара:": "Quantity of goods:",
  "Количество:": "Quantity:",
  "Комиссия": "Commission",
  "Комиссия банка": "Commission of the bank",
  "Конец": "End",
  "Контакты:": "Contacts:",
  "Кофемашина": "Coffee machine",
  "КПП": "Checkpoint",
  "КПП:\r\n\r\nОГРН: \r\n\r\nОКОПФ: \r\n\r\nОКВЭД: \r\n\r\nСистема налогообложения:\r\n\r\nУставный капитал:": "\"CPT:\r\n\r\nOGRN: \r\n\r\nOKOPF: \r\n\r\nOKVED: \r\n\r\nTaxation system:\r\n\r\nAuthorized capital:\"",
  "Краткое наименование организации:": "Short name of the organization:",
  "Круглосуточно": "Around the clock",
  "Куда": "Where to",
  "Купить": "Buy",
  "Купить устройство": "Buy device",
  "Курьер": "Courier",
  "м": "m",
  "Магазин": "Shop",
  "Магазин самообслуживания": "Supermarket",
  "Магазины отсутствуют": "No stores",
  "Макеты оклейки микромаркета": "Layouts of pasting micromarket",
  "Максимальная мощность": "Maximum power",
  "Максимальный": "Maximum",
  "Маркетинг": "Marketing",
  "Меню": "Menu",
  "Место рождения": "Place of Birth",
  "месяц": "month",
  "месяцы": "months",
  "Микромаркет": "Micromarket",
  "Минимальная мощность": "Minimum power",
  "Минимальный": "Minimum",
  "Минимальный размер и формат": "Minimal size and format",
  "Минимальный размер и формат:": "Minimum size and format:",
  "модификации": "modifications",
  "Модуль": "Module",
  "Мой сайт готов к приему реальных заказов": "My site is ready to receive real orders",
  "Мощность разогрева блюда": "Heating power for food",
  "Мы автоматически загрузили вашу кофемашину стандартными напитками. Для правильного функционирования торговой точки активируйте товары и назначьте цену.": "We have automatically loaded your coffee machine with standard drinks. For the correct functioning of the tradepoint, activate the goods and set the price.",
  "Мы запустили новую версию личного кабинета.": "We have launched a new version of the personal account.",
  "Перейти к старой версии?": "Go to the previous version?",
  "На главную": "To main",
  "На заказ (наборы скидок)": "To order (discount sets)",
  "На категорию": "Per category",
  "На Категорию ID :id": "To Category ID: id",
  "На карте": "On Map",
  "На основе каталога": "Based on catalog",
  "На рекламный блок": "Per ad unit",
  "На сайте есть контактная информация и юридические реквизиты": "The site has contact information and legal details",
  "На сайте есть описание товаров или услуг, цена, условия возврата и доставки": "The site has a description of goods or services, price, terms of return and delivery",
  "На стекло": "On glass",
  "На товар": "For goods",
  "На товар ID :id": "For product ID: id",
  "Назад": "Back",
  "Назад к списку": "Back to the list",
  "Название": "Name",
  "Название банка": "Bank's name",
  "Название вашей скидки в бэкофисе": "Name of your back office discount",
  "Название заказа": "Order name",
  "Название каталога": "Directory name",
  "Название каталога...": "Directory name ...",
  "Название категории": "name of category",
  "Название категории...": "Name of category...",
  "Название компании": "The name of the company",
  "Название меню": "Menu name",
  "Название набора": "Set name",
  "Название организации": "Name of the organization",
  "Название отчета": "Report title",
  "Название промокода": "Promo code name",
  "Название товара": "Product Name",
  "Название товара две строки": "Product name two lines",
  "Название тор. точки": "Store name",
  "Название торговой точки": "Tradepoint name",
  "Название торговой точки:": "Tradepoint name:",
  "Название торговой точки...": "Tradepoint name ...",
  "Название устройства": "Device name",
  "Назначить": "Assign",
  "Назначить условия доставки": "Assign delivery terms",
  "Наименование банка": "Name of the bank",
  "Наименование банка:": "Name of the bank:",
  "Наименование категории": "Category name",
  "Наименование платежа": "Payment Description",
  "Наименование производителя": "Manufacturer's name",
  "Наименование товара": "Name of product",
  "Наименование товаров и услуг": "Name of goods and services",
  "Наименование торговой точки": "Tradepoint name",
  "Найдены ошибки": "Found errors",
  "Наклейки:": "Stickers:",
  "Наличие": "Availability",
  "Наличие в торговых точках": "Availability in retail outlets",
  "Наличие товаров": "Availability of goods",
  "Наличие:": "Availability:",
  "Налог": "Tax",
  "Настольный баннер": "Table banner",
  "Настроить интеграцию": "Set up integration",
  "Настройка таблицы": "Setting up a table",
  "Настройки": "Settings",
  "Настройки профиля": "Profile settings",
  "Настройте интеграцию со своей системой товароучёта и автоматически заполните склад товарами.\r\nВнимание!\r\nДля корректного заполнения всех полей может потребоваться пригласить вашего технического специалиста": "Set up integration with your billing system and automatically fill your warehouse with goods.\r\nAttention!\r\nFor correct filling of all fields you may need to invite your technical specialist.",
  "Наценка": "Extra charge",
  "Нач. остаток": "Beginning remainder",
  "Нач. сумма": "Beginning sum",
  "Начало": "Start",
  "не активна": "inactive",
  "Не валидный телефон": "Not valid phone number",
  "Не валидный email": "Not valid email",
  "Не выбран": "Not selected",
  "Не выбрана": "Not selected",
  "Не выбрано": "Not chosen",
  "Не задан": "Not set",
  "Не заполнено": "Not filled",
  "Не знаете где будет торговая точка?": "Don't know where the outlet will be?",
  "Не облагается": "Is not a subject to a tax",
  "Не установлен": "Not set",
  "Неделя": "A week",
  "нет": "No",
  "нет данных": "there is no data",
  "Нет данных": "No info",
  "Нет данных по указанным параметрам": "No data on the specified parameters",
  "Нет добавленных сотрудников": "No added employees",
  "Нет расписания": "No timetable",
  "Нет сотрудников": "No employees",
  "Новая поставка": "New delivery",
  "Новая скидка": "New discount",
  "Новая скидка набора": "New set discount",
  "Новая торговая точка": "New tradepoint",
  "Новое перемещение": "New move",
  "Новое списание": "New write-off",
  "Новое юридическое лицо": "New legal entity",
  "Новый виртуальный терминал": "New virtual terminal",
  "Новый каталог": "New catalog",
  "Новый отчет": "New report",
  "Новый товар": "New product",
  "Номер...": "Number...",
  "О категории": "About category",
  "О товаре": "About product",
  "Обновление успешно": "Update successful",
  "Обновления": "Updates",
  "Оборудование": "Equipment",
  "Общая выручка": "total revenues",
  "общая скидка": "general discount",
  "Общее кол-во товара:": "The total amount of goods:",
  "Общий доход": "Total income",
  "Общий срок годности": "General shelf life",
  "Объем": "Volume",
  "Обязательное поле": "Required field",
  "ОГРН": "OGRN",
  "ОКВЭД": "OKVED",
  "ОКОПФ": "OKOPF",
  "Онлайн-витрина": "Online showcase",
  "Операции": "Operations",
  "Операции будут доступны после первого платежа в подключенных магазинах.": "Operations will be available after the first payment in connected stores.",
  "Операции отсутствуют": "There are no operations",
  "Описание": "Description",
  "Описание состава...": "Description of the composition ...",
  "Описание товара": "Product description",
  "Описание...": "Description...",
  "Оплата": "Payment",
  "Оплата №": "Payment No.",
  "Оплата № :id": "Payment #: id",
  "Оплата ID :id": "Payment ID :id",
  "Оплата ID": "Payment ID",
  "Оплаты": "Payments",
  "Определите условия доставки, площадь и возможность самовывоза.": "Determine the terms of delivery, area and the possibility of self-pickup.",
  "Организация": "Organization",
  "Основная информация": "basic information",
  "Основной": "Basic",
  "Основные данные": "Basic data",
  "Основные настройки": "basic settings",
  "Особенности применения": "Application features",
  "Особенности применения:": "Application features:",
  "Остатки": "Remains",
  "Ответственный": "Responsible",
  "Ответственный сотрудник": "Responsible employee",
  "отключен": "disabled",
  "Открыт": "Open",
  "Откуда": "Where from",
  "Откуда:": "Where from:",
  "Отмена": "Cancel",
  "Отменить": "Cancel",
  "Отметьте, в случае если доступ к торговой точке ограничен для посторонних посетителей": "Check this box if access to the outlet is restricted for unauthorized visitors",
  "Отсутствует каталог": "Missing directory",
  "Отчество": "middle name",
  "Отчет по движению": "Storage report",
  "Отчетный период": "Reporting period",
  "Отчётный период": "Reporting period",
  "Отчеты": "Reports",
  "Отчёты": "Reports",
  "Отчёты по движению": "Storage reports",
  "Отчёты по движению отсутствуют\r\nВ данном разделе вы сможете формировать различные отчёты по движению товаров с заданными параметрами, а так же скачать полученные данные в форматах .csv и .xlsx.": "\"Reports on the movement of goods.\r\nIn this section you can generate various reports on the movement of goods with the specified parameters, as well as download the data in .csv and .xlsx formats\".",
  "Оформить возврат": "Make a return",
  "Оформление возврата": "Making a return",
  "Оффлайн": "Offline",
  "Очистить": "Clear",
  "Очистить фильтры": "Clear filters",
  "Ошибка активации": "Activation error",
  "Параметры набора": "Dialing options",
  "Параметры скидки": "Discount options",
  "Паспорт": "Passport",
  "Паспорт:": "Passport:",
  "первая покупка без учета времени": "first purchase excluding time",
  "первая покупка после назначенного временного лимита": "first purchase after the assigned time limit",
  "Первая страница": "First page",
  "Перезагрузить": "Reboot",
  "Перезагрузить кофемашину": "Restart the coffee machine",
  "Перезагрузка кофемашины может помочь в случае некоторых неисправностей устройства.": "Restarting the espresso machine can help in case of some malfunctions of the machine.",
  "Перейти": "Go to",
  "Переместить в каталог": "Move to directory",
  "Переместить в категорию": "Move to category",
  "Перемещение :id": "Move: id",
  "Перемещение №": "Moving No.",
  "перемещения": "displacement",
  "Перемещения": "Movements",
  "Перемещения отсутствуют": "No movement",
  "Период": "Period",
  "Период действия": "Validity",
  "Период действия скидки": "Discount period",
  "Персональные данные": "Personal Information",
  "Персональные данные индивидульного предпринимателя:": "Personal data of an individual entrepreneur:",
  "Печатать дату изготовления": "Print production date",
  "Печатать срок годности": "Print expiration date",
  "Печать стикера": "Sticker Printing",
  "Печать стикера и ценника": "Sticker and price tag printing",
  "Питание кофемашины": "Powering the coffee machine",
  "Питание устройства": "Powering the device",
  "Платежи": "Payments",
  "ПН, СР, ПТ": "MON, Wed, Fri",
  "По вашему запросу ничего не найдено": "No results were found for your search.",
  "По данному заказу ещё не было возвратов": "There have been no returns for this order yet.",
  "По значению этого префикса система распознаёт весовой товар с выделением цифрового кода товара и его веса в граммах. Доступные префиксы — от 20 до 29. Если префикс не указан, то товары будут обработаны, как штучные.": "By the value of this prefix, the system recognizes the item by weight, highlighting the digital code of the item and its weight in grams. Available prefixes are from 20 to 29. If no prefix is specified, then the goods will be processed as piece goods.",
  "По товару": "By Good",
  "По торговой точке": "By point of sale",
  "По умолчанию": "Default",
  "Подвердить": "Confirm",
  "подключен": "connected",
  "Подключите первый уже сейчас.": "Connect the first one now.",
  "Подключить": "Connect",
  "Подолжить": "Continue to",
  "Подписан": "Signed by",
  "Подробнее": "More details",
  "Подтвердить": "Confirm",
  "Поиск": "Search",
  "Поиск по товару": "Search by item",
  "Показать все": "show all",
  "Показать все товары": "Show all items",
  "Показать еще": "Show more",
  "Показать ещё": "Show more",
  "Показать по 10": "Show by 10",
  "Показать по 25": "Show by 25",
  "Показать по 50": "Show by 50",
  "Показать по 7": "Show by 7",
  "Показывать в приложении": "Show in app",
  "полгода": "half a year",
  "Полное наименование компании:": "Full company name:",
  "Полное наименование организации": "Full name of the organization",
  "Полное наименование организации:": "Full name of the organization:",
  "Популярные товары": "Popular goods",
  "Порядок применения": "Application procedure",
  "Поставка :id": "Delivery: id",
  "Поставка №": "Delivery No.",
  "поставки": "supply",
  "Поставки": "Supply",
  "Поставок пока нет": "No supplies yet",
  "Поступление": "Admission",
  "Превью меню": "Menu preview",
  "Предзаказ": "Pre-order",
  "Префиксы штрихкода весового товара": "Weight item barcode prefixes",
  "Привязать еще устройство": "Link another device",
  "Привязать к торговой точке": "Link to a point of sale",
  "Привязать устройство": "Bind device",
  "Привязка каталога": "Directory binding",
  "Придумайте название": "Come up with a name",
  "Приекрепленная торговая точка": "Attached point of sale",
  "Прикрепите устройства для новой торговой точки. Например, Briskly модуль поможет вам со статистикой.": "Attach devices for a new store. For example, the Briskly module will help you with statistics.",
  "Прикрепить склад": "Attach warehouse",
  "Прикрепить существующие": "Attach existing",
  "Прикреплен к торговым точкам:": "Attached to tradepoints:",
  "Приложение": "Application",
  "Применить": "Apply",
  "Применить для всех": "Apply for everyone",
  "Применить к:": "Apply to:",
  "Применять в торговый точках:": "Apply in retail outlets:",
  "Применять в торговых точках:": "Apply to retail outlets:",
  "Принимает заказы покупателей и собирает их в торговом зале для отправки клиенту": "Accepts orders from customers and collects them on the trading floor for shipment to the client",
  "Причина списания": "Reason for writing off",
  "Проверить документы": "Check documents",
  "Проверка списка": "Checking the list",
  "Программист": "Programmer",
  "Продано": "Sold",
  "Продолжить": "Proceed",
  "Производство с доставкой": "Production with delivery",
  "Промокоды": "Promo codes",
  "Промоматериалы": "Promo materials",
  "Пропустить": "Skip",
  "Протокол авторизации": "Authorization protocol",
  "Профиль пользователя": "User profile",
  "Радиус": "Radius",
  "Радиус (м)": "Radius (m)",
  "Раздел скидок пуст": "Discounts section is empty",
  "Размер наклейки, мм": "Sticker size, mm",
  "Размер скидки": "Discount size",
  "Размер скидки:": "Amount of discount:",
  "Размерность": "Dimension",
  "Разогрев блюда": "Reheating dishes",
  "Расписание": "Schedule",
  "Расписание, уведомления и другие настройки": "Schedule, notifications and other settings",
  "Расчётная 10%": "Calculated 10%",
  "Расчётная 20%": "Calculated 20%",
  "Рассылки": "Newsletters",
  "Расход": "Consumption",
  "Расчетный": "Estimated",
  "Регистрационный номер:": "Registration number:",
  "Регистрация": "registration",
  "Редактирование перемещения": "Editing a move",
  "Редактирование поставки": "Editing a delivery",
  "Редактирование списания": "Editing a write-off",
  "Редактирование торговой точки": "Editing a point of sale",
  "Редактировать": "Edit",
  "Реквизиты": "Requisites",
  "Реквизиты заполнены успешно, и направлены на проверку.": "The requisites have been filled in successfully and sent for verification.",
  "Реквизиты терминала": "Terminal details",
  "Реквизиты торговой точки": "Store requisites",
  "Реквизиты:": "Requisites:",
  "Роль": "Role",
  "Роль в компании": "Role in the company",
  "Роль сотрудника:": "Employee role:",
  "Роль:": "Role:",
  "Сайт вашего магазина": "Your store website",
  "Самовывоз клиентом": "Customer pickup",
  "Сбербанк": "Sberbank",
  "Сгенерировать ссылку": "Generate link",
  "сделайте это сейчас.": "do it now.",
  "сделать это сейчас": "do it now",
  "Себестоимость": "Cost price",
  "Серийный номер": "Serial number",
  "Серийный номер / Ключ активации": "Serial Number / Activation Key",
  "Серия": "Series",
  "Сигаретный шкаф": "Cigarette cabinet",
  "Система налогообложения": "Tax system",
  "Система товароучёта": "Inventory System",
  "Система ТУ": "TU system",
  "Скидка": "Discount",
  "Скидка в процентах": "Percentage discount",
  "Скидка в рублях (фиксированная скидка)": "Discount in rubles (fixed discount)",
  "Скидки": "Discounts",
  "Скидки на заказ": "Discounts on order",
  "Скидки на категорию": "Category discounts",
  "Скидки на товар": "Product discounts",
  "Скидки на товары": "Discounts on goods",
  "Скидки набора": "Set discounts",
  "Скидки:": "Discounts:",
  "Склад": "Warehouse",
  "Склад / Остатки": "Warehouse / Leftovers",
  "Склад / Отчет :id": "Warehouse / Report: id",
  "Склад / Перемещение": "Warehouse / Relocation",
  "Склад / Перемещения": "Warehouse / Movements",
  "Склад / Поставка": "Warehouse / Delivery",
  "Склад / Поставки": "Warehouse / Delivery",
  "Склад / Списания": "Warehouse / Write-off",
  "Склад и товары": "Warehouse and merchandise",
  "Скрыть все уведомления": "Hide all notifications",
  "Скрыть подсказку": "Hide hint",
  "Собственная доставка": "Own delivery",
  "создайте": "create",
  "создайте новый": "create a new",
  "Создание возврата": "Create a return",
  "Создание каталога": "Creating a directory",
  "Создание перемещения": "Create a move",
  "Создание поставки": "Delivery creation",
  "Создание списания": "Write off",
  "Создание терминала": "Terminal creation",
  "Создание товара": "Product creation",
  "Создание торговой точки": "Creation of a point of sale",
  "Создание успешно": "Creation successful",
  "Создать": "Create",
  "Создать каталог": "Create directory",
  "создать Каталоги": "create directories",
  "Создать категорию": "Create a category",
  "создать Набор скидок": "create a set of discounts",
  "создать новую": "create a new",
  "Создать новый": "Create new",
  "Создать новый каталог": "Create a new directory",
  "Создать отчет по движению": "Create motion report",
  "Создать сотрудника": "Create employee",
  "Создать ссылку": "Create link",
  "Создать счёт": "Create bill",
  "Сопутствующие товары": "Related products",
  "Состав": "Composition",
  "Состав заказа": "Order list",
  "Состав меню": "Menu composition",
  "Состав перемещения": "Travel composition",
  "Состав поставки": "Scope of delivery",
  "Состав списания": "Write-off composition",
  "Состав:": "Composition:",
  "Состояние торговой точки": "Outlet status",
  "Сотрудники": "Employees",
  "Сохранить": "Save",
  "Списание :id": "Write-off: id",
  "Списание №": "Write-off No.",
  "Списаний нет": "No write-offs",
  "списания": "write-offs",
  "Списания": "Write-offs",
  "Списания / Списание": "Write-off / Write-off",
  "Список ваших каталогов и категорий пока пуст.": "The list of your catalogs and categories is still empty.",
  "Список ваших товаров с заданными параметрами пока пуст.": "The list of your products with the specified parameters is currently empty.",
  "Список ваших товаров пока пуст.": "The list of your products is still empty.",
  "Список ссылок": "Links list",
  "Список терминалов пока пуст.": "The terminal list is still empty.",
  "Список товаров доступных в торговой точке": "List of products available at the outlet",
  "Способ операции": "Method of operation",
  "Способ оплаты": "Payment method",
  "Средний чек": "Average check",
  "Средняя мощность": "Average power",
  "Срок годности": "Shelf life",
  "Срок годности:": "Shelf life:",
  "Ссылка для доступа": "Access link",
  "Ссылка на витрину товаров": "Link to product showcase",
  "Ссылка на оплату:": "Payment link:",
  "Стандарт": "Standard",
  "Стандарт:": "Standard:",
  "Статус": "Status",
  "Статус возврата": "Return status",
  "Статус заказа": "Order status",
  "Статус кофемашины": "Coffee machine status",
  "Статус платежа": "Payment status",
  "Статус поставки": "Delivery status",
  "Статус реквизитов": "Requisites status",
  "Статус скидки": "Discount status",
  "Статус списания": "Write-off status",
  "Статус товара": "Item status",
  "Стикер": "Sticker",
  "Стоимость доставки": "Delivery cost",
  "Стр. с пропиской": "P. with registration",
  "Страница меню": "Menu page",
  "Страница сотрудника": "Employee page",
  "Страница товара": "Product page",
  "Страницы паспорта:": "Passport pages:",
  "Сумма": "Amount",
  "Сумма возврата:": "Refund amount:",
  "Сумма заказа": "Order amount",
  "Сумма заказа:": "Order price:",
  "Сумма корзины:": "Cart amount:",
  "Сумма перемещения:": "Movement amount:",
  "Сумма:": "Amount:",
  "Сформировать": "Generate",
  "Сформировать валидный ШК автоматически": "Generate a valid CC automatically",
  "Сформировать отчет": "Generate a report",
  "Счета": "Accounts",
  "Счета на оплату": "Invoices for payment",
  "Счета отсутствуют": "Accounts missing",
  "Т": "T",
  "Телефон": "Telephone",
  "Телефон организации": "Organization phone",
  "Телефон торговой точки": "Point of sale phone",
  "Телефон:": "Telephone:",
  "Температура": "Temperature",
  "Температура внутри": "Inside temperature",
  "Терминал": "Terminal",
  "Тип": "Type of",
  "Тип договора": "Contract type",
  "Тип доставки": "Delivery type",
  "Тип заказа": "Order type",
  "Тип напитка": "Beverage type",
  "Тип округления": "Rounding type",
  "Тип оплаты": "Payment type",
  "Тип отчета": "Report type",
  "Тип скидки": "Discount type",
  "Тип торговой точки": "Tradepoint type",
  "Тип уведомлений": "Notification type",
  "Тип:": "Type of:",
  "То, что будет видеть пользователь во вкладке % поверх бренда компании": "What the user will see in the % tab on top of the company brand",
  "Товар №": "Goods №",
  "Товары": "Goods",
  "Торговая точка": "Trade point",
  "Торговая точка поставки": "Point of sale",
  "Торговая точка списания": "Write-off point of sale",
  "Торговая точка №": "Store №.",
  "Торговые точки": "Stores",
  "Торговые точки отсутствуют": "There are no outlets",
  "Торговые точки отсутствуют\r\nВ этом разделе вы можете отслеживать работу своих торговых точек и настраивать их. Для начала создайте первую торговую точку": "No stores.\r\nIn this section you can monitor the operation of your stores and configure them. Start by creating your first store",
  "Торговые точки терминала": "Terminal points of sale",
  "Торговые точки:": "Stores:",
  "Торговых точек:": "Stores:",
  "Точность округления": "Rounding Accuracy",
  "У вас есть полный доступ ко всему функционалу личного кабинета.": "You have full access to all the functionality of your personal account.",
  "У вас пока нет оплат": "You have no payments yet",
  "Убрать из категории": "Remove from category",
  "Уведомления о состоянии соединения": "Connection status notifications",
  "Уведомления об изменении уровня температуры": "Temperature level change notifications",
  "Углеводы": "Carbohydrates",
  "Удалить": "Delete",
  "Удалить каталог": "Delete directory",
  "Удалить категорию": "Delete category",
  "Удалить отчет": "Delete report",
  "Удалить сотрудника": "Remove employee",
  "Удалить товар": "Delete item",
  "Удалить торговую точку": "Delete sales outlet",
  "Удалить устройсвто": "Delete device",
  "Удалить устройство?": "Delete device?",
  "Уйти без сохранения": "Leave without saving",
  "Укажите": "Please indicate",
  "Укажите адрес": "Enter the address",
  "Укажите заголовок": "Specify a title",
  "Укажите код с устройства": "Enter the code from the device",
  "Укажите название": "Please enter a title",
  "Укажите радиус, в зоне действия которого клиент сможет взаимодействовать с торговой точкой": "Specify the radius in the coverage area of ​​which the client will be able to interact with the outlet",
  "Укажите реквизиты для отправки денег на расчётный счёт и выдачи чека клиентам.": "Specify details for sending money to the current account and issuing checks to customers.",
  "Уставной капитал": "Authorized capital",
  "Установите расписание работы вашего микромаркета: в какое время он открывается для покупок, а в какое время блокируется.": "Set a schedule for your micromarket: at what time it opens for purchases, and at what time it is blocked.",
  "Установите расписание работы вашего микромаркета. По умолчанию режим работы круглосуточный.": "Set a schedule for your micromarket. By default, the operating mode is round-the-clock.",
  "Устройства": "Devices",
  "Устройство": "Device",
  "Фактический адрес организации": "The actual address of the organization",
  "Фактический адрес: \r\nТел\r\nР/с\r\nБИК": "\"Actual Address: \r\nTel\r\nP/S\r\nBIK",
  "Фамилия": "Surname",
  "Фильтры": "Filters",
  "ФИО": "Full name",
  "ФИО:": "FULL NAME:",
  "Фирменный стаканчик": "Branded cup",
  "Фото": "Photo",
  "Фото товара": "Product photo",
  "Цена": "Price",
  "Цена доставки": "Delivery price",
  "Цена за стаканчик": "Price per cup",
  "Цена товара": "The price of the product",
  "Цена:": "Price:",
  "Ценник": "Price tag",
  "ч": "h",
  "Час": "Hour",
  "Часовой пояс": "Timezone",
  "Чек-ер": "Checker",
  "Чекер": "Checker",
  "Что нового": "What's new",
  "Чтобы задать параметры, сначала выберите тип отчета.": "To set the parameters, first select the type of report.",
  "Чтобы настроить состав меню, пожалуйста выберите базовый каталог": "To customize the menu composition, select the base directory",
  "Чтобы прявязать сотрудника к торговой точке выберите необходимую ниже.": "To bind an employee to a sales outlet, select the one you need below.",
  "Чтобы сменить каталог выберите необходимый ниже.": "To change the directory, select the required one below.",
  "Чтобы сменить категорию выберите необходимую ниже.": "To change the category, select the one you need below.",
  "Чтобы создать ссылку укажите ответственного сотрудника и время действия ссылки.": "To create a link, specify the responsible employee and the time of the link.",
  "Чтобы сформировать отчет, необходимо выбрать тип и задать параметры.": "To generate a report, you must select the type and set the parameters.",
  "ШК товара": "SK of goods",
  "шт": "pcs.",
  "Штрихкод": "Barcode",
  "Штрихкод:": "Barcode:",
  "Эквайринг": "Acquiring",
  "Экранное меню": "OSD menus",
  "Экспорт каталога": "Export catalog",
  "Экспорт отчета": "Export report",
  "Экспорт перемещения": "Export movement",
  "Экспорт поставки": "Export delivery",
  "Экспорт списания": "Export write-off",
  "Экспорт товара": "Export of goods",
  "Экспорт торговых точек": "Outlets export",
  "Экспорт устройства": "Export device",
  "Электронная почта": "Email",
  "Этаж": "Floor",
  "этаж:": "floor:",
  "Юридические лица": "Legal entities",
  "Юридическое лицо": "Entity",
  "Юридическое лицо ID :id": "Legal entity ID :id",
  "Январь": "January",
  "all": "all",
  "ID или название торговой точки": "ID or name of the trade point",
  "ID клиента": "Client ID",
  "ID товара": "Item ID",
  "ID торговой точки": "Store ID",
  "Plus-500 (R290)": "Plus-500 (R290)",
  "R10NS (без рекламного блока)": "R10NS (no ad unit)",
  "R10NSG (с рекламным блоком)": "R10NSG (with ad unit)",
  "R4NG, R5NG": "R4NG, R5NG",
  "R5N": "R5N",
  "R7N, R7M": "R7N, R7M",
  "Чек оплаты": "Receipt",
  "Посмотреть чек": "View receipt",
  "Отчет №": "Report №",
  "Сменить компанию-партнёра": "Change the partner company",
  "Вы можете перейти в компанию партнёра. Выберите нужную из списка.": "You can switch to a partner company. Select the desired one from the list.",
  "Сменить компанию": "Change company",
  "Для активации торговой точки и её полного функционирования необходимо заполнить": "To activate a tradepoint and make it fully functional",
  "основных раздела": "main sections",
  "Онлайн-корзины": "Online-carts",
  "Для продаж нужны товары. Создайте или прикрепите<br>склад с товарами к вашей торговой точке": "You need merchandise to sell. Create or attach<br>warehouse with goods to your tradepoint",
  "Прикрепите устройства для новой торговой точки. Например": "Прикрепите устройства для новой торговой точки. Например",
  "Посмотреть": "Look in",
  "Порядок напитков": "Order of drinks",
  "Укажите в каком порядке будут выводиться напитки на экране вашей кофемашины.": "Specify the order in which drinks will be displayed on the screen of your coffee machine.",
  "Замок закрыт": "The lock is closed",
  "Закрыта": "Closed",
  "Открыть": "Open",
  "Уведомления": "Notifications",
  "Ссылка на чек": "Link to receipt",
  "Поиск по таблице": "Search",
  "Экспорт оплаты": "Export payment",
  "Скоро все изменится!": "Everything will change soon!",
  "Пн": "Mon",
  "Вт": "Tue",
  "Ср": "Wed",
  "Чт": "Thu",
  "Пт": "Fri",
  "Сб": "Sat",
  "Вс": "Sun",
  "Половину вверх": "Half up",
  "Половину вниз": "Half down",
  "Целиком вверх": "Ceil",
  "Целиком вниз": "Floor",
  "Для добавления товаров Вам необходимо": "To add products you need to",
  "и": "and",
  "В случае загрузки товаров через .xlsx и наличия в файле заполненной графы “Категория”, вам необходимо создать только Каталог, Категории при этом будут созданы автоматичеcки.": "In the case of loading goods via .xlsx and the presence in the file filled column “Category”, you only need to create a catalog, categories will be created automatically.",
  "В разделе “Документы” размещены все выставленные вам по договору оферты счета, а также акты выполненных работ.": "In “Documents” section contains all the invoices issued to you under the contract of offer, as well as the acts of work performed.",
  "В данном блоке вы сможете отслеживать статус ваших счетов на оплату.": "In this block you will be able to track the status of your invoices for payment.",
  "Вы можете": "You can",
  "вручную или": "manually or",
  "через .xlsx": "through .xlsx",
  "В данном разделе вы сможете формировать различные отчёты по вашей деятельности в виде таблиц с заданными параметрами, а так же будете иметь возможность выгрузить полученные данные в форматах .csv и .xlsx": "In this section you can generate various reports on your activities in the form of tables with specified parameters, as well as you will be able to unload the data in .csv and .xlsx formats.",
  "В этом разделе вы можете отслеживать работу своих торговых точек и настраивать их. Для начала": "In this section you can monitor your outlets and configure them. To start with",
  "первую торговую точку": "your first tradepoint",
  "Вы изменили настройки печати стикера, но не сохранили данные": "You have changed the sticker printing settings, but have not saved the data.",
  "Сохранить и распечатать": "Save and print"
};

